document.addEventListener("DOMContentLoaded", function () {
    const leftSelect = document.querySelector("[data-column='left']");
    const rightSelect = document.querySelector("[data-column='right']");
    const switchButton = document.querySelector("[data-switch-lang='true']");
    const glossaryItems = document.querySelectorAll("[data-glossary-item]");

    function updateGlossaryView(column, langCode) {
        let selector = `.glossary__column[data-content-column='${column}']  [data-glossary-item] `;
        document.querySelectorAll(selector)
            .forEach(item => {
                item.classList.toggle("u-hidden", item.dataset.lang !== langCode);
            });
    }

    function syncDetailsToggle(detailsElement) {
        const glossaryId = detailsElement.dataset.glossaryItem;
        const langCode = detailsElement.dataset.lang;
        const isOpen = detailsElement.open;
        console.log("glossaryId: ", glossaryId);
        console.log("langCode: ", langCode);


        // Find the corresponding item in the other column
        const counterparts = document.querySelectorAll(
            `[data-glossary-item='${glossaryId}'][data-lang]:not([data-lang='${langCode}'])`
        );
        counterparts.forEach(counterpart => {
            counterpart.open = isOpen;
        });
    }

    // Event listener for language selection
    [leftSelect, rightSelect].forEach(select => {
        select.addEventListener("change", (event) => {
            const column = event.target.dataset.column;
            const selectedLang = event.target.value;
            console.log({ column, selectedLang });
            updateGlossaryView(column, selectedLang);
        });
    });

    // Event listener for swap button
    switchButton.addEventListener("click", () => {
        const tempLang = leftSelect.value;
        leftSelect.value = rightSelect.value;
        rightSelect.value = tempLang;

        // Trigger change event to update UI
        leftSelect.dispatchEvent(new Event("change"));
        rightSelect.dispatchEvent(new Event("change"));
    });

    // Event listener to sync opening/closing of glossary items
    glossaryItems.forEach(item => {
        item.addEventListener("toggle", () => {
            syncDetailsToggle(item);
        });
    });
});
